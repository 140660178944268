export const Types = {
    INITIALIZE_CART: "INITIALIZE_CART",
    UPDATE_CART: "UPDATE_CART",
    SAVE_CART: "SAVE_CART",
    CLEAR_CART: "CLEAR_CART",
    FETCH_PROGRESS: "FETCH_PROGRESS",
    UPDATE_PROGRESS: "UPDATE_PROGRESS",
    SET_FEED: "SET_FEED",
    FETCH_FEED: "FETCH_FEED",
    INITIALIZE_PERSON: "INITIALIZE_PERSON",
    SET_PERSON: "SET_PERSON",
    PURCHASED: "PURCHASED",
}